import '../styles/globals.css'
import React, { ReactNode, useEffect, useRef } from 'react'
import App from 'next/app'
import type { AppProps, AppContext } from 'next/app'
import { useRouter } from 'next/router'
import Link from 'next/link'
import {
	ChakraProvider,
	Box,
	HStack,
	Spacer,
	Button,
	IconButton,
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
} from '@chakra-ui/react'
import { HamburgerIcon, ArrowBackIcon } from '@chakra-ui/icons'
import basicAuthCheck from '../utils/basicAuthCheck'
import { AuthProvider, useAuth } from '../context/auth'
import { auth } from '../lib/firebase-client'

const AppContainer = ({ children }: { children: ReactNode }) => {
	const router = useRouter()

	const { isOpen, onOpen, onClose } = useDisclosure()
	const btnRef = useRef() as React.MutableRefObject<HTMLButtonElement>

	const { isLoading, logout, currentDriver } = useAuth()

	const handleLogout = async (): Promise<void> => {
		await logout()
		onClose()
	}

	const showHeader = () => {
		return router.pathname.match(/\/login/) ? false : true
	}

	const showHamberger = () => {
		return router.pathname.match(/\/$/) ? true : false
	}

	return isLoading ? (
		<Box p={4}>...loading</Box>
	) : (
		<>
			{showHeader() && (
				<>
					{' '}
					<Box
						bgColor="#fff"
						px={4}
						height={14}
						boxShadow="lg"
						position="fixed"
						width="full"
						zIndex={10}
					>
						<HStack height="full">
							{showHamberger() ? (
								<IconButton
									aria-label="Menu"
									variant="unstyled"
									icon={<HamburgerIcon boxSize={6} />}
									ref={btnRef}
									onClick={onOpen}
								/>
							) : (
								<Link href="/">
									<IconButton
										aria-label="Back"
										variant="unstyled"
										icon={<ArrowBackIcon boxSize={6} />}
									/>
								</Link>
							)}
							<Spacer />
							{currentDriver && <Box fontWeight="bold">{currentDriver.name}さん</Box>}
							<Spacer />
							<Box width={6}></Box>
						</HStack>
					</Box>
					<Drawer isOpen={isOpen} placement="left" onClose={onClose} finalFocusRef={btnRef}>
						<DrawerOverlay />
						<DrawerContent>
							<DrawerCloseButton />
							<DrawerHeader></DrawerHeader>
							<DrawerBody></DrawerBody>
							<DrawerFooter>
								<Button onClick={handleLogout}>ログアウト</Button>
							</DrawerFooter>
						</DrawerContent>
					</Drawer>
				</>
			)}
			<Box>{children}</Box>
		</>
	)
}

function MyApp({ Component, pageProps }: AppProps) {
	const router = useRouter()

	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			if (!user) {
				router.push('/login')
			}
		})
	}, [auth])

	return (
		<AuthProvider auth={auth}>
			<ChakraProvider>
				<AppContainer>
					<Component {...pageProps} />
				</AppContainer>
			</ChakraProvider>
		</AuthProvider>
	)
}

MyApp.getInitialProps = async (appContext: AppContext) => {
	const { req, res } = appContext.ctx
	if (req && res && process.env.ENABLE_BASIC_AUTH === 'true') {
		await basicAuthCheck(req, res)
	}

	const appProps = await App.getInitialProps(appContext)
	return { ...appProps }
}

export default MyApp
