import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
	apiKey: process.env.FIREBASE_KEY,
	authDomain: process.env.FIREBASE_DOMAIN,
	databaseURL: process.env.FIREBASE_DATABASE,
	projectId: process.env.FIREBASE_PROJECT_ID,
	storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
}

if (!firebase.apps.length) {
	firebase.initializeApp(config)
}

export const auth = firebase.auth()
export const getToken = async (): Promise<string> =>
	auth.currentUser ? await auth.currentUser.getIdToken() : ''
export const firestore = firebase.firestore()
export const storage = firebase.storage()
export const storageRef = firebase.storage().ref()
